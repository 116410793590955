// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// An lightweight paralax animation library
import lax from 'lax.js';

// A fancy chart library to display charts on the front-end

// Maps
import ChartTool from '@components/ChartTool.vue';
import GoogleMapEmbed from '@components/GoogleMapEmbed.vue';
import LaxScrollAnimation from '@components/LaxScrollAnimation.vue';

// ================================================
// SUI Components
// ================================================

import ClickToggle from '@sgroup/click-toggle';
import MobileNav from '@sgroup/mobile-nav';

// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
} from '@headlessui/vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        GoogleMapEmbed,
        LaxScrollAnimation,
        ChartTool,
    },

    provide() {
        lax.init();

        lax.addDriver('scrollY', () => {
            return window.scrollY;
        });

        return {
            lax,
        };
    },

    data() {
        return {
            navActive: false,
            stickyNav: true,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.

        // A minimal responsive menu with just vanilla JS
        new MobileNav();

        // A search box for header. Handles clicking away
        new ClickToggle({
            openSelector: '[data-search-open]',
            activeClass: 'search-open',
            clickAwaySelector: '[data-header-search-form]',
        });

        // Search focus state
        document
            .querySelector('[data-search-open]')
            .addEventListener('click', (e) => {
                document.getElementById('search').focus();
            });

        const teamBtn = document.querySelector('.js-team-btn');
        const teamCommBtn = document.querySelector('.js-committee-btn');

        const teamCards = document.querySelectorAll('.js-team-member');
        const commCards = document.querySelectorAll('.js-committee-member');
        const allCards = document.querySelectorAll('.team-card');

        if (teamCommBtn) {
            teamCommBtn.addEventListener('click', (event) => {
                teamBtn.classList.toggle('opacity-50');
                teamCommBtn.classList.toggle('opacity-50');

                allCards.forEach((e) => {
                    if (!e.classList.contains('js-committee-member')) {
                        e.classList.add('hidden');
                    } else if (e.classList.contains('js-committee-member')) {
                        e.classList.remove('hidden');
                    }
                });
            });

            teamBtn.addEventListener('click', (event) => {
                teamCommBtn.classList.toggle('opacity-50');
                teamBtn.classList.toggle('opacity-50');

                allCards.forEach((e) => {
                    if (!e.classList.contains('js-team-member')) {
                        e.classList.add('hidden');
                    } else if (e.classList.contains('js-team-member')) {
                        e.classList.remove('hidden');
                    }
                });
            });

            teamBtn.click();
        }

        const scrollTop = document.querySelector('.js-scroll-top');

        if (scrollTop) {
            scrollTop.addEventListener('click', (event) => {
                const topId = document.getElementById('app');

                topId.scrollIntoView({
                    behavior: 'smooth',
                });
            });
        }
    },
}).mount('#app');
